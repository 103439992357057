<script lang="ts" setup>
const { t } = useT();
const { isMobile } = useDevice();
const route = useRoute();
const { open, isOpen } = useAppModals();
const { data: appInitData } = useAppInitData();
const isProfileCompleted = computed(() => appInitData.value?.profileCompleted);
const { isFinOperationAvailable } = useCashHelper();
const { name, surname } = useProfileData();
const { openDefault } = usePresetPackageController();
const [isShow, toggleSidebar] = useToggle(false);
const { isEventTheme } = useEventsCollector();

const isLoggedIn = computed(() => appInitData.value?.isGuest === false);

const userAbbreviation = computed(() => `${name.value?.[0] ?? ""}${surname.value?.[0] ?? ""}`);
const logoImg = computed(() => `/nuxt-img/${isEventTheme.value ? "black-friday-" : ""}logo-icon.svg`);
const isIngamePopup = computed(() => isOpen("LazyOModalGame"));

const handleOpenCash = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "header",
		step: "view_payments_info"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
	if (!isFinOperationAvailable.value) {
		return;
	}
	openDefault();
};

const handleToggleSidebar = () => {
	toggleSidebar();
};

const handleLogin = () => {
	open("LazyOModalLogin");

	dispatchGAEvent({
		event: "login",
		location: "header",
		step: "start"
	});
};

const handleSignup = () => {
	open("LazyOModalSignup");

	dispatchGAEvent({
		event: "registration",
		location: "header",
		step: "start"
	});
};
</script>
<template>
	<AHeader data-tid="header" :class="{ 'logged-in': isLoggedIn }">
		<div class="header-left">
			<AIconButton variant="ghost" class="menu-btn" data-tid="burger-menu" @click.prevent="handleToggleSidebar">
				<NuxtIcon name="20/burger" filled />
			</AIconButton>
			<NuxtLink v-show="isLoggedIn || route?.path !== '/'" class="logo" to="/">
				<NuxtImg :src="logoImg" alt="logo" width="292" height="160" data-tid="logo" />
			</NuxtLink>
		</div>

		<div v-if="isLoggedIn" class="header-right">
			<OWrapperOnboard :isTarget="isIngamePopup" locationKey="game" :actionKey="['next', 'finish']" finalFlow>
				<OHeaderBalance />
			</OWrapperOnboard>
			<AAnimationPulse color="var(--cairo)" :width="12" active>
				<AButton variant="primary" data-tid="header-buy-btn" @click="handleOpenCash">
					<AText variant="toledo" class="text-tlalnepantla">{{ t("Buy") }}</AText>
				</AButton>
			</AAnimationPulse>
			<div class="notification">
				<ONotificationCenter />
			</div>
			<div class="separator" />
			<div class="account-link__user">
				<AText variant="toledo" :modifiers="['extrabold']" class="text-cannes">
					{{ name ? t("Hello,") : t("Hello!") }}
				</AText>
				<AText v-if="name" variant="toledo" :modifiers="['semibold', 'capitalize']" class="text-cannes">
					{{ `${name.toLowerCase()}${!isProfileCompleted ? "!" : ""}` }}
				</AText>
				<NuxtLink v-if="!isProfileCompleted" to="/complete-profile/">
					<AText variant="tempe" :modifiers="['bold', 'underline']" class="text-coventry complete">
						{{ t("Complete Profile") }}
					</AText>
				</NuxtLink>
			</div>
			<NuxtLink v-if="!isMobile" to="/game/" class="account-link" data-tid="header-avatar">
				<NuxtImg
					v-if="!isProfileCompleted && !name"
					src="/nuxt-img/account/avatar.png"
					format="webp"
					alt="avatar"
					width="48"
					height="48"
				/>

				<ABadge v-else :size="48" background="var(--garoua)">
					<ABadge :size="44" background="var(--cuiaba)">
						<AText variant="toledo" :modifiers="['bold', 'uppercase']" class="text-tlalnepantla">
							{{ userAbbreviation }}
						</AText>
					</ABadge>
				</ABadge>
			</NuxtLink>
		</div>

		<div v-if="!isLoggedIn" class="buttons-auth">
			<AButton variant="secondary" size="lg" class="login-btn" data-tid="header-login-btn" @click="handleLogin">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Log in") }}</AText>
			</AButton>
			<AButton variant="primary" size="lg" class="signup-btn" data-tid="header-register-btn" @click="handleSignup">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Sign up") }}</AText>
			</AButton>
		</div>
	</AHeader>

	<OSidebar class="sidebar" :show="isShow" @toggle-sidebar="handleToggleSidebar" />
	<LazyOMobNavigation v-if="isMobile && !Boolean(route.query.game)" @toggle-sidebar="handleToggleSidebar" />
</template>

<style scoped lang="scss">
header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 104px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 36px;
	z-index: 22;

	&.logged-in {
		background-color: var(--carabanchel);
		position: fixed;
	}

	@include media-breakpoint-down(lg) {
		padding: 0 16px;
		min-height: 56px;
		&:not(.logged-in) {
			display: none;
		}
		&.logged-in {
			&:deep {
				.menu-btn,
				.account-link,
				.account-link__user,
				.separator {
					display: none;
				}
			}
		}
	}

	@media (orientation: portrait) and (max-width: 992px) {
		&.logged-in {
			&:deep {
				.notification {
					position: fixed;
					z-index: 9;
					right: 0;
					bottom: 200px;
					width: 48px;
					height: 48px;
					border-radius: 8px 0 0 8px;
					background: var(--gosaba);
					border-left: 2px solid var(--cannes);
					border-top: 2px solid var(--cannes);
					border-bottom: 2px solid var(--cannes);
					border-right: none;
					box-shadow: 0 0 8px var(--cangzhou);
				}
			}
		}
	}

	.complete {
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}

	.back-home {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.pulse button {
		--a-button-primary-before-content: "";

		&::before {
			opacity: 1;
			background-color: transparent;
		}
	}
}
.header-left,
.header-right,
.buttons-auth {
	display: flex;
	align-items: center;
	gap: 24px;

	@include media-breakpoint-down(md) {
		gap: 8px;
	}

	button {
		width: 130px;

		@include media-breakpoint-down(md) {
			width: 60px;
		}
	}

	.login-btn {
		border-radius: 8px;
	}
}

.header-right {
	flex-grow: 1;
	justify-content: flex-end;
}

.logo {
	display: inline-flex;

	@include media-breakpoint-down(lg) {
		display: block !important;
		margin-left: 8px;
	}

	& > img {
		width: 146px;
		height: 80px;

		@include media-breakpoint-down(lg) {
			width: 80px;
			height: 44px;
		}
	}

	.img-logged {
		margin-top: -6px;
	}
}

.menu-btn {
	&:hover {
		cursor: pointer;
	}
	.nuxt-icon {
		font-size: 20px;
	}
}

.account-link {
	display: flex;
	justify-content: center;
	gap: 16px;

	&__user {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.separator {
	height: 40px;
	width: 2px;
	background: var(--garoua);
}

.notification {
	border-radius: 8px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--chifeng);
	border: 2px solid var(--celaya);
}
</style>
